'use client'

import { FormattedMessage } from 'react-intl'
import { Button, Cell, Icon, Image, Spacer, Text } from '@vinted/web-ui'
import { Pencil12, X12 } from '@vinted/monochrome-icons'

import { SeparatedList } from '@marketplace-web/shared/ui-helpers'
import { FeedbackCommentModel } from 'types/models'

import FeedbackThumbnail from '../FeedbackThumbnail'
import FeedbackUsername from '../FeedbackUsername'
import { FeedbackScreen, useFeedbackContext } from '../../FeedbackProvider'

type Props = {
  id: number
  canDeleteComment: boolean
  canChangeComment: boolean
  comment: FeedbackCommentModel
}

const Comment = ({ id, canDeleteComment, canChangeComment, comment }: Props) => {
  const { setScreenById } = useFeedbackContext()

  const handleDelete = () => {
    setScreenById(id, FeedbackScreen.DeleteComment)
  }

  const handleEdit = () => {
    setScreenById(id, FeedbackScreen.EditComment)
  }

  const renderCommentActions = () => (
    <>
      <Spacer />
      <SeparatedList>
        {canChangeComment && (
          <Button
            text={<FormattedMessage id="user.feedback.edit" />}
            icon={<Icon name={Pencil12} color="parent" />}
            inline
            size={Button.Size.Small}
            theme="muted"
            onClick={handleEdit}
          />
        )}
        {canDeleteComment && (
          <Button
            text={<FormattedMessage id="user.feedback.delete" />}
            icon={<Icon name={X12} color="parent" />}
            inline
            size={Button.Size.Small}
            theme="muted"
            onClick={handleDelete}
          />
        )}
      </SeparatedList>
    </>
  )

  return (
    <Cell
      title={<FeedbackUsername user={comment.user} isSystem={comment.isSystem} />}
      prefix={
        <FeedbackThumbnail
          user={comment.user}
          size={Image.Size.Medium}
          isSystem={comment.isSystem}
        />
      }
    >
      <Text as="span">{comment.text}</Text>
      {renderCommentActions()}
    </Cell>
  )
}

export default Comment
