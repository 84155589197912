import { transformPaginationDto } from '@marketplace-web/domain/pagination-data'
import { UserDto } from '@marketplace-web/shared/user-data'
import { FeedbackListResp } from 'types/api'
import { FeedbackCommentDto, FeedbackDto, FeedbackUserDto } from 'types/dtos'
import {
  FeedbackCommentModel,
  FeedbackListModel,
  FeedbackModel,
  FeedbackUserModel,
} from 'types/models'

export const transformUser = (user?: FeedbackUserDto | UserDto): FeedbackUserModel | undefined => {
  if (!user) return undefined

  const photoUrl = 'photo' in user ? user.photo?.url : user.photo_url

  return {
    id: user.id,
    login: user.login,
    profileUrl: user.profile_url,
    photoUrl: photoUrl || undefined,
  }
}

export const transformComment = (
  { comment, user }: FeedbackCommentDto,
  isSystem: boolean,
): FeedbackCommentModel | undefined => {
  if (!comment) return undefined

  return {
    user: transformUser(user),
    text: comment,
    isSystem,
  }
}

export const transformFeedback = ({
  id,
  user,
  feedback,
  user_id,
  feedback_user_id,
  rating,
  created_at_ts,
  system_feedback,
  comment,
  is_system_comment,
  can_comment,
  can_change,
  can_delete,
  can_change_comment,
  can_delete_comment,
  can_moderate,
  localization,
}: FeedbackDto): FeedbackModel => ({
  id,
  text: feedback,
  userId: user_id,
  feedbackUserId: feedback_user_id,
  rating,
  user: transformUser(user),
  comment: transformComment(comment, !!is_system_comment),
  createdAt: created_at_ts,
  isSystem: system_feedback,
  canReply: can_comment,
  canChange: can_change,
  canDelete: can_delete,
  canChangeComment: can_change_comment,
  canDeleteComment: can_delete_comment,
  canModerate: !!can_moderate,
  localization,
})

export const transformFeedbackResponse = ({
  pagination,
  user_feedbacks,
}: FeedbackListResp): FeedbackListModel => ({
  pagination: transformPaginationDto(pagination),
  feedback: user_feedbacks.map(transformFeedback),
})
