import { Image } from '@vinted/web-ui'

import { UserImage } from '@marketplace-web/shared/ui-helpers'
import { FeedbackUserModel } from 'types/models'

type Size = Pick<ComponentProps<typeof Image>, 'size'>

type Props = Size & {
  user?: FeedbackUserModel
  isSystem: boolean
}

const FeedbackThumbnail = ({ user, size, isSystem }: Props) => {
  if (isSystem) {
    return <UserImage styling={Image.Styling.Circle} size={size} isSystem />
  }

  if (user) {
    return (
      <a href={user.profileUrl}>
        <UserImage styling={Image.Styling.Circle} size={size} src={user.photoUrl} />
      </a>
    )
  }

  return <UserImage styling={Image.Styling.Circle} size={size} />
}

export default FeedbackThumbnail
